import React from "react";
import styles from "./page-our-work.module.css";

import Section from "./section";
import WorkCategories from "./work-categories";

const PageOurWork = () => {
  return (
    <Section bg="darkGeo">
      <div className={styles.inner}>
        <h1 className={styles.title}>Our work</h1>
        <p className={styles.para}>{/* insert copy here */}</p>
      </div>
      <WorkCategories />
    </Section>
  );
};

export default PageOurWork;
